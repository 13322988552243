import * as THREE from 'three'
import { render, events, extend } from '@react-three/fiber'
import './styles.css'
import App from './App'

extend(THREE)

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight
}

let a = 1


window.addEventListener('resize', () =>
// this.camera.aspect = sizes.width / sizes.height,
// this.camera.updateProjectionMatrix(),
// sizes.width = window.innerWidth,
// sizes.height = window.innerHeight,
a = ++a,
  render(<App />, document.querySelector('canvas'), {
    events,
    linear: true,
    camera: { fov: 30, position: [0, 0, 6] },
    // https://barradeau.com/blog/?p=621
    // This examples needs WebGL1 (?)
    
    gl: new THREE.WebGL1Renderer({
      canvas: document.querySelector('canvas'),
      antialias: true,
      alpha: true
    })
  }),
  
  
  //gl.setSize(sizes.width, sizes.height),
  
)

window.dispatchEvent(new Event('resize'))
